import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/node_modules/@heroui/button/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Image"] */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/node_modules/@heroui/image/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/root/bamboo-agent1-home/xml-data/build-dir/DIGIPOST-VNLOGI1-SEAGAME2021WEBDOCKER/src/components/layout/MainLayout/Header.tsx");
